
import { defineComponent, onMounted, ref, reactive, nextTick, h, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup() {
    const router: any = useRouter()
    const handleJump = (path: string) => {
      router.push(path)
    }
    return {
      handleJump
    }
  }
})
